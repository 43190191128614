import { twMerge } from "tailwind-merge";
import logo from "./sg-logo.png";
import white_sg_logo from "./white_logo.png";

export const Logo = ({
  className,
  noLink,
  white = false,
}: {
  className?: string;
  noLink?: true;
  white?: boolean;
}) => {
  const theLogo = (
    <img
      src={white ? white_sg_logo : logo}
      width={90}
      className={twMerge("", className)}
      alt="Switchgrid Logo"
    />
  );
  if (noLink) {
    return theLogo;
  }

  return <a href="/">{theLogo}</a>;
};
